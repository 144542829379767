import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./estilo.css"

export default function Servico(){

    return(

        
        <Container>
        <div>
            <h2 style={{textAlign:"center", color:"blue"}}>Os nossos Serviços</h2>
            <p style={{textAlign:"center", color:"green"}}>Ligue-nos ou passe por cá, damos Orçamentos gratuitos</p>
        </div>

        <Row>

            <Col lg={3} className="caixa">
                <div style={{width:"48%", float:"left", textAlign:"center"}}>   
                        <img src="img/oleo.jpg" width="50%" />
                </div>
                <div style={{width:"48%", float:"right"}}>
                        <h4>Manutenção</h4>
                        <p>Fazemos a Manutenção periódica ao seu Automóvel.</p>
                </div>
            </Col>

            <Col lg={3} className="caixa">
                <div style={{width:"48%", float:"left", textAlign:"center"}}>   
                        <img src="img/chave.png" width="50%" />
                </div>
                <div style={{width:"48%", float:"right"}}>
                        <h4>Reparação</h4>
                        <p>Reparamos o seu Automóvel avariado ou acidentado.</p>
                </div>
            </Col>

            <Col lg={3} className="caixa">
                <div style={{width:"48%", float:"left", textAlign:"center"}}>   
                        <img src="img/pincel.jpg" width="50%" />
                </div>
                <div style={{width:"48%", float:"right"}}>
                        <h4>Pintura e Chaparia</h4>
                        <p>Consertamos a chaparia de seu carro e pintamos</p>
                </div>
            </Col>
            
            <Col lg={3} className="caixa">
                <div style={{width:"48%", float:"left", textAlign:"center"}}>   
                        <img src="img/electricidade.png" width="50%" />
                </div>
                <div style={{width:"48%", float:"right"}}>
                        <h4>Electricidade Automóvel</h4>
                        <p>detectamos e reparamos Avarias electricas</p>
                </div>
            </Col>

           <Col lg={3} className="caixa">
                <div style={{width:"48%", float:"left", textAlign:"center"}}>   
                        <img src="img/venda.png" width="50%" />
                </div>
                <div style={{width:"48%", float:"right"}}>
                        <h4>Venda de Automóveis</h4>
                        <p>Venda de Automóveis usados.</p>
                </div>
            </Col> 
                                   
        </Row>
    </Container>
    
    );
}