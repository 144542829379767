import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Carousel2 from "./Carousel2"

export default function Inicio(){

    return(
        <>
        <br />
        <Container>
            <Row className="conteudo" >
                <Col lg={3}  >
                <h5 style={{color:"blue"}}>O seu carro tem um problema pode relaxar, <br />
                nós resolvemos! </h5>
                <p>Desde 1987 que cuidamos de carros.</p>
                </Col>
                <Col xs={5} >
                    <Carousel2 />
                </Col>
                <Col lg={3} >
                <p>As pequenas ou grandes reparações fazem parte das situações do dia-a-dia.<br />
                Na nossa oficina efectuamos na hora pequenas ou grandes reparações gerais ou marcamos.<br />
                Quer saber mais? Faça já a sua marcação.</p>
                </Col>
            </Row>
            <br />
            <Row>
                <Col lg={4} className="caixa" style={{textAlign:"center"}}>
                    <img src="img/oleo.jpg" width="20%"alt="" />
                    <h4>Mudanças de Oleo</h4>
                    <p>Oferta da Mão de Obra</p> 
                </Col>
                <Col lg={3} className="caixa" style={{textAlign:"center"}}>
                    <img src="img/venda.png" width="20%"alt="" />
                    <h4>Carro de Substituição</h4>
                    <p>durante o tempo de reparação e havendo disponibilidade de carro</p>
                </Col>
                <Col lg={4} className="caixa" style={{textAlign:"center"}}>
                    <img src="img/mecan.png" width="20%"alt="" />
                    <h4>Levamos á Inspecção</h4>
                    <p>fazemos uma revisão e levamos á inspecção</p>

                </Col>
            </Row>
        </Container>
        </>
    );
}