import Carousel from 'react-bootstrap/Carousel';


function Carousel2() {
  return (
    <Carousel>
      <Carousel.Item>
        <img src="carousel/1.png" width="100%" />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src="carousel/2.png" width="100%" />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src="carousel/3.png" width="100%" />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousel2;