import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./estilo.css"

export default function Empresa() {

    return(
        <Container>			
			<br />
            <h5>Empresa</h5>
				<Row>	
                    <Col lg={6} >
                    <img src="img/armazem.png" width="50%" />
                    </Col>
                    
                    <Col lg={5} >
                    Dedica-se a:<br/>
                    <ul>
                        <li>Mecânica Auto</li>
                        <li>Bate Chapas</li>
                        <li>Pintura Auto</li>
                        <li>Electricidade Auto</li>
                    	<li>Venda de Automóveis Usados</li>
						<hr/>
                        <li>Preparamos o seu Veículo e Levamos à Inspecção se o desejar.</li>
                    </ul>
                    </Col>
                </Row>    
            </Container>

    );

}