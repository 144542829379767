import Inicio from "./componentes/Inicio"
import Empresa from "./componentes/Empresa"
import Servico from "./componentes/Servico"
import Contacto from "./componentes/Contacto" 



import { BrowserRouter, Routes, Link, Route } from 'react-router-dom'
import {Nav} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  return (
    <div >
      <div style={{textAlign: 'center', margin:"auto", fontSize:"18px", color:"yellow", backgroundColor:"black"}}>Albergauto - comércio e reparação de Automóveis</div>
      <BrowserRouter>
      <Nav variant="tabs" >
        <Nav.Link as={Link} to="/">Inicio</Nav.Link>
        <Nav.Link as={Link} to="/empresa">Empresa</Nav.Link>
        <Nav.Link as={Link} to="/servico">Serviços</Nav.Link>
        <Nav.Link as={Link} to="/contacto">Contactos</Nav.Link>
      </Nav>
      
      <Routes>
        <Route path="/" index element={<Inicio />}></Route>
        <Route path="/empresa" element={<Empresa/>}></Route>
        <Route path="/servico" element={<Servico/>}></Route>
        <Route path="/contacto" element={<Contacto />}></Route>
      </Routes>
      </BrowserRouter>

      
    
    </div>
  );
}

export default App;
